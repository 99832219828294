import {Injectable} from '@angular/core';
import {
	AuthService,
	copyFormControl,
	CountryService,
	extractIDsFromArray,
	formatDateForServer,
	getChangedProperties,
	LocationRequest,
	PaginationService,
	RemoveDialogService,
	SimpleEntityService,
	SnackBarService
} from '@customcoding/shared';
import {PatientCreateRequest, PatientUpdateRequest} from './patient.model';
import {HttpClient} from '@angular/common/http';
import {MinimalPatient, Patient} from '../shared/models/patient.obj';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {SchoolService} from "../shared/services/school.service";
import {Caretaker, CaretakerRequestData} from "../shared/models/caretaker.obj";
import moment from "moment/moment";
import {defaultIfEmpty, tap} from "rxjs/operators";
import {Table} from "primeng/table";

@Injectable({
	providedIn: 'root'
})
export class PatientService extends SimpleEntityService<Patient, PatientCreateRequest, PatientUpdateRequest> {

	//_allPatients: Patient[];

	//private patientSubject = new BehaviorSubject<any[]>([]);
	//patients$ = this.patientSubject.asObservable();

	private minimalPatientDataSubject: BehaviorSubject<MinimalPatient[]> = new BehaviorSubject<MinimalPatient[]>([]);
	public minimalPatientData$: Observable<MinimalPatient[]> = this.minimalPatientDataSubject.asObservable();


	constructor(
		http: HttpClient,
		pagination: PaginationService,
		private schoolService: SchoolService,
		private removeDialogService: RemoveDialogService,
		private countryService: CountryService,
		private snackBar: SnackBarService,
		private authService: AuthService
	) {
		super(http, pagination, '/api/scap/patient');


	}

	applyTemplate(patient_id: number, template_id: number, selectedFolder: any = null): Observable<{}> {

		let apiUrl = `${this.apiEndpoint}/${patient_id}/template/${template_id}`;
		if (selectedFolder) {
			apiUrl += `/folder/${selectedFolder.id}`;
		}

		return this.http.post(apiUrl, {});
	}

	// ================================================================================================================
	// Caretakers handling
	removeCaretaker(index: number, caretakers: FormArray) {
		const caretaker = caretakers.at(index);

		if (this.schoolService.existsOnBackend(caretaker.value)) { // if caretaker exists on backend, delete him
			this.removeDialogService.openRemoveDialog('Sorgerechtsperson', caretaker.value.type, '/api/scap/caretaker/' + caretaker.value.id)
				.subscribe(() => caretakers.removeAt(index));
		} else {
			this.removeDialogService.openRemoveDialogWithoutRoute('Sorgerechtsperson', caretaker.value.type)
				.subscribe(() => caretakers.removeAt(index));
		}
	}

	copyCaretaker(caretaker: FormGroup, caretakers: FormArray) {
		const newFormGroup = copyFormControl(caretaker);

		// the copy does not exist in the backend yet
		newFormGroup.controls.id.setValue(`unsaved_${Math.random() * 1000000}`);
		newFormGroup.markAsDirty(); // new caretaker is dirty
		caretakers.markAsDirty({onlySelf: true}); // because a new caretaker was added

		caretakers.push(newFormGroup);
	}

	addCaretaker(caretaker: Caretaker, caretakers: FormArray): FormGroup {
		const birthdate = moment(caretaker.person.birthdate, "Y-MM-DD");

		const newCaretaker = new FormGroup({
			id: new FormControl(caretaker.id),
			type: new FormControl(caretaker.type),
			description: new FormControl(caretaker.description), familyStatus: new FormControl(caretaker.familyStatus),
			isLivingAddress: new FormControl(Boolean(caretaker.isLivingAddress)),
			hasCustody: new FormControl(caretaker.hasCustody),
			workPhone: new FormControl(caretaker.workPhone),
			// person
			firstname: new FormControl(caretaker.person.firstname),
			lastname: new FormControl(caretaker.person.lastname),
			personEmail: new FormControl(caretaker.person.email),
			telephone: new FormControl(caretaker.person.telephone),
			mobile: new FormControl(caretaker.person.mobile),
			birthdate: new FormControl(birthdate.isValid() ? birthdate.toDate() : null),
			personLanguages: new FormControl(extractIDsFromArray(caretaker.person.languages)),

			community: new FormControl(caretaker.community),
			street: new FormControl(),
			houseNumber: new FormControl(),
			city: new FormControl(),
			postalCode: new FormControl(),
			country_short: new FormControl()
		});
		if (caretaker.person.location) {
			newCaretaker.patchValue({
				street: caretaker.person.location.street,
				houseNumber: caretaker.person.location.houseNumber,
				city: caretaker.person.location.city,
				postalCode: caretaker.person.location.postalCode,
				country_short: caretaker.person.location.country?.short
			});
		}

		caretakers.push(newCaretaker);
		return newCaretaker;
	}

	createNewCaretaker(addCaretakerInput: FormControl, caretakers: FormArray) {
		if (addCaretakerInput.invalid) {
			addCaretakerInput.markAsTouched();
			return;
		}

		const newCaretaker = new FormGroup({
			id: new FormControl(`unsaved_${Math.random() * 1000000}`),
			type: new FormControl(addCaretakerInput.value),
			description: new FormControl(),
			familyStatus: new FormControl(),
			isLivingAddress: new FormControl(false),
			hasCustody: new FormControl(false),
			workPhone: new FormControl(),
			// person
			firstname: new FormControl(),
			lastname: new FormControl(),
			personEmail: new FormControl(),
			telephone: new FormControl(),
			mobile: new FormControl(),
			personLanguages: new FormControl(),
			birthdate: new FormControl(),

			community: new FormControl(),

			street: new FormControl(),
			houseNumber: new FormControl(),
			city: new FormControl(),
			postalCode: new FormControl(),
			country_short: new FormControl(this.countryService.getDefaultCountry().short)
		});
		caretakers.push(newCaretaker);
		newCaretaker.markAsDirty(); // new caretaker is dirty
		caretakers.markAsDirty({onlySelf: true}); // because a new caretaker was added

		// reset addCaretakerInput
		addCaretakerInput.reset('Mutter'); // default value is needed, see `addCaretakerInput` declaration
		addCaretakerInput.markAsUntouched();
		addCaretakerInput.markAsPristine();
		addCaretakerInput.updateValueAndValidity();
	}

	async saveCaretakers(
		caretakers: FormArray,
		patientId: number,
		caretakerTable: Table
	) {
		let showNothingChangedMessage = true;
		return new Promise<boolean>((resolve) => {
			const observables: Observable<any>[] = [];

			for (const ct of caretakers.controls) {
				const caretaker = ct as FormGroup;
				let changedCaretakerData: CaretakerRequestData & Partial<LocationRequest>;
				if (this.schoolService.existsOnBackend(caretaker.value)) {
					changedCaretakerData = getChangedProperties(caretaker) as CaretakerRequestData;
				} else {
					changedCaretakerData = caretaker.value;
				}
				// fill out required fields
				changedCaretakerData.isLivingAddress = caretaker.value.isLivingAddress;
				changedCaretakerData.hasCustody = caretaker.value.hasCustody;
				changedCaretakerData.patient_id = patientId;
				if (changedCaretakerData.hasCustody || changedCaretakerData.isLivingAddress) {
					changedCaretakerData.community = caretaker.value.community;
				}

				if (changedCaretakerData.birthdate) {
					changedCaretakerData.birthdate = formatDateForServer(changedCaretakerData.birthdate)
				}

				// deleteNullProperties(changedCaretakerData); // backend does not like null
				if (!changedCaretakerData.birthdate) {
					delete changedCaretakerData.birthdate;
				}
				if (!(changedCaretakerData as any).personLanguages) {
					delete (changedCaretakerData as any).personLanguages;
				}
				if (!(changedCaretakerData as any).city) {
					delete (changedCaretakerData as any).city;
				}

				if (caretaker.dirty) {
					showNothingChangedMessage = false;
					if (caretakerTable.isRowExpanded(caretaker)) {
						caretakerTable.toggleRow(caretaker);
					}
					if (this.schoolService.existsOnBackend(caretaker.value)) { // update existing caretaker
						if (changedCaretakerData.isLivingAddress || changedCaretakerData.hasCustody) {
							changedCaretakerData.community = caretaker.value.community;
						}
						observables.push(
							this.http.patch('/api/scap/caretaker/' + caretaker.value.id, changedCaretakerData).pipe(
								tap(() => caretaker.markAsPristine())
							)
						);
					} else { // create new caretaker
						if (!changedCaretakerData.community) {
							delete changedCaretakerData.community;
						}
						observables.push(
							this.http.post('/api/scap/caretaker', changedCaretakerData).pipe(
								tap((ct) => {
									caretaker.patchValue({id: ct.id});
									caretaker.markAsPristine();
								})
							)
						);
					}
				}
			}
			forkJoin(observables).pipe(defaultIfEmpty(null)).subscribe((allData) => {
				if (allData)
					this.snackBar.showSuccessDialog(`Sorgerechtsperson geändert.`);
				return resolve(showNothingChangedMessage);
			}, e => {
				this.snackBar.showServerError(`Sorgerechtsperson konnte nicht geändert werden.`, e);
				return resolve(showNothingChangedMessage);
			});
		});
	}

	// Assign patient to doctors
	assignPatientToUsers(patientId: number, userIds: number[]) {
		return this.http.patch(`/api/scap/patient/${patientId}/users`, {users: userIds});
	}

	checkPatientRegistrationNumberAvailability(registrationNumber: number) {
		return this.http.get(`/api/scap/patients/registration-number?registration_number=${registrationNumber}`);
	}

	/*
	getAllPatients(){

		return this._allPatients;

	}
*/

	/*
        getAllPatients(): Observable<Patient[]> {


            if(this.patientSubject.value.length === 0){

                this.all().subscribe( (c) => {
                    this.patientSubject.next(c);
                })

                return this.patients$;

            }
        }
    */
	saveAllPatientsOnInit(): Observable<MinimalPatient[]> {

		return this.http.get<MinimalPatient[]>('/api/scap/patient/minimal/data').pipe(
			tap(patients => {
				console.log("minimal patient data:::", patients);
				this.minimalPatientDataSubject.next(patients);
			})
		)


		/*
			this.patientDataSubject.next(data);


		if(!this._allPatients){
			const patients:Patient[] = [];
			let params = {};
			this.all().subscribe((p) => {
				const patients = p;
				console.log("patients count = ", patients.length);
				console.log("all patients? => ", patients);
			})
	}
*/

	}
}
